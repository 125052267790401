import { AssetProps } from 'contentful-management/dist/typings/export-types';
import {
  CategoryMgmtEntry,
  ContentType,
  ContentTypeLabel,
  EntriesResponse,
  EntryNotFound,
  EntryResponse,
  GeneralMgmtEntry,
  isEntryNotFound,
  MenuItemMgmtEntry,
  MenuMgmtEntry,
} from 'services/api/types';
import { getStatusFromEntitySys } from './EntityStatus';
import { defaultLocale } from './Locales';

export const getOptionsFromSelected = (
  selected: string[],
  data: EntriesResponse | AssetProps[],
): Array<'delete' | 'unpublish' | 'publish' | 'archive' | 'unarchive'> => {
  const result: Array<'delete' | 'unpublish' | 'publish' | 'archive' | 'unarchive'> = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const foundItems = (data as any[]).filter((option) => selected.includes(option.sys.id));
  const itemStatuses = foundItems.map((item) => getStatusFromEntitySys(item.sys));

  if (itemStatuses.some((status) => status === 'DRAFT')) {
    result.push('delete', 'archive', 'publish');
  }
  if (itemStatuses.some((status) => status === 'CHANGED')) {
    result.push('unpublish', 'publish');
  }
  if (itemStatuses.some((status) => status === 'PUBLISHED')) {
    result.push('unpublish');
  }
  if (itemStatuses.some((status) => status === 'ARCHIVED')) {
    result.push('unarchive', 'delete');
  }

  return result;
};

export const reorderItems = <T>(list: Array<T>, source: number, destination: number): Array<T> => {
  const result: Array<T> = Array.from(list);
  const [removed] = result.splice(source, 1);
  result.splice(destination, 0, removed);

  return result;
};

export const sortItems = (
  list: Array<EntryResponse | EntryNotFound>,
  sortedList: Array<string>,
): Array<EntryResponse | EntryNotFound> => {
  const listCopy = list.slice();
  listCopy.sort((a, b) => {
    return sortedList.indexOf(a.sys.id) - sortedList.indexOf(b.sys.id);
  });

  return listCopy;
};

export const sortAssets = (
  list: Array<AssetProps | EntryNotFound>,
  sortedList: Array<string>,
): Array<AssetProps | EntryNotFound> => {
  const listCopy = list.slice();
  listCopy.sort((a, b) => {
    return sortedList.indexOf(a.sys.id) - sortedList.indexOf(b.sys.id);
  });

  return listCopy;
};

export const getEntryTitleByType = (
  type: ContentType,
  entry: EntryResponse | EntryNotFound,
): string => {
  if (isEntryNotFound(entry)) {
    return 'ENTRY IS MISSING OR INACCESSIBLE';
  }
  switch (type) {
    case 'menuItem': {
      const typedEntry = entry as MenuItemMgmtEntry;
      return typedEntry.fields.title ? typedEntry.fields.title[defaultLocale] : 'Untitled';
    }

    case 'category': {
      const typedEntry = entry as CategoryMgmtEntry;
      return typedEntry.fields.title ? typedEntry.fields.title[defaultLocale] : 'Untitled';
    }

    case 'menu': {
      const typedEntry = entry as MenuMgmtEntry;
      return typedEntry.fields.title ? typedEntry.fields.title[defaultLocale] : 'Untitled';
    }
    case 'general': {
      const typedEntry = entry as GeneralMgmtEntry;
      return typedEntry.fields.name ? typedEntry.fields.name[defaultLocale] : 'Untitled';
    }

    default:
      return '';
  }
};

export const getLabelFromContentTypeId = (contentTypeId: ContentType): ContentTypeLabel => {
  switch (contentTypeId) {
    case 'category':
      return 'Category';

    case 'general':
      return 'Info';

    case 'menu':
      return 'Menu';

    case 'menuItem':
      return 'Menu Item';

    default:
      return '';
  }
};

export const getStoreSlugFromMenuEntry = (menu: MenuMgmtEntry) => {
  return `Store: ${
    menu.fields.storeSlug?.[defaultLocale] ? menu.fields.storeSlug[defaultLocale] : 'All'
  }`;
};

export const getStoreSlugFromCategoryEntry = (menu: CategoryMgmtEntry) => {
  return `Store: ${
    menu.fields.storeSlug?.[defaultLocale] ? menu.fields.storeSlug[defaultLocale] : 'All'
  }`;
};
