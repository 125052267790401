import React from 'react';
import { Text, Flex, Tag } from '@chakra-ui/react';
import { ContentType, EntryResponse } from 'services/api/types';
import { ItemActions } from 'shared/functions';
import { StatusLabel } from 'shared/components';

type EntryItemProps = {
  entry: EntryResponse;
  onClickEntry: (entry: EntryResponse) => void;
  isSelected: boolean;
  contentTypeId: ContentType;
};

export const AddEntryItem: React.FC<EntryItemProps> = ({
  entry,
  onClickEntry,
  isSelected,
  contentTypeId,
}) => {
  return (
    <Flex
      onClick={() => onClickEntry(entry)}
      border="1px solid"
      borderColor={isSelected ? 'blue.200' : 'gray.200'}
      borderRadius="lg"
      _hover={{
        cursor: 'pointer',
        backgroundColor: 'gray.50',
        borderColor: 'blue.200',
        boxShadow: 'md',
      }}
      flexDirection="column"
      marginBottom="6"
      backgroundColor={isSelected ? 'gray.50' : 'white'}
      boxShadow={isSelected ? 'md' : 'sm'}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid"
        borderColor="gray.200"
        paddingX="4"
        paddingY="2"
      >
        <Text>{ItemActions.getLabelFromContentTypeId(contentTypeId)}</Text>
        <StatusLabel sys={entry.sys} />
      </Flex>
      <Flex padding="4" flexDirection={'column'} alignItems="flex-start">
        <Text fontWeight="semibold">{ItemActions.getEntryTitleByType(contentTypeId, entry)}</Text>
        {contentTypeId === 'menu' && (
          <Tag color="white" backgroundColor="blue.300">
            {ItemActions.getStoreSlugFromMenuEntry(entry)}
          </Tag>
        )}
        {contentTypeId === 'category' && (
          <Tag color="white" backgroundColor="blue.300">
            {ItemActions.getStoreSlugFromCategoryEntry(entry)}
          </Tag>
        )}
      </Flex>
    </Flex>
  );
};
