import React, { useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Flex } from '@chakra-ui/react';
import { GeneralMgmtEntry, MenuMgmtEntry, RequestErrorType } from 'services/api/types';
import { EntriesAPI } from 'services/api';
import { MenuForm } from './components/MenuForm';
import { BackButton } from 'shared/components';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import { useFetchByContentType } from 'features/Dashboard/useFetchAllData';
import { defaultLocale } from 'shared/functions';

type MenuParams = {
  menuId: string;
};

type MenuLocationState = {
  menu?: MenuMgmtEntry;
};

export const Menu: React.FC = () => {
  const history = useHistory();
  const { state: accountsState } = useContext(AccountsStateContext);
  const { selectedAccount } = accountsState;
  const { state: locationState = {} } = useLocation<MenuLocationState>();
  const { menuId = '' } = useParams<MenuParams>();

  const { data: generalData } = useFetchByContentType('general', selectedAccount);

  const { data: menu, isLoading } = useQuery(
    ['entryById', menuId],
    () => EntriesAPI.getSingleEntry(menuId),
    {
      initialData: locationState.menu,
      enabled: !!selectedAccount,
      retry: 0,
      onError: (error: RequestErrorType) => {
        if (error.status && error.status === 404) {
          history.push(`/store/content/${selectedAccount?.id}/menu`);
        }
      },
    },
  );

  let storeSlugs: string[] = [];
  if (generalData) {
    storeSlugs = (generalData as GeneralMgmtEntry[]).map(
      (entry) => entry.fields.slug[defaultLocale],
    );
  }

  return (
    <Flex flex="1" flexDirection="column" maxWidth="100%" paddingX={{ base: '4', sm: 0 }}>
      <BackButton />
      {!isLoading && <MenuForm menu={menu as MenuMgmtEntry} storeSlugs={storeSlugs} />}
    </Flex>
  );
};
