import { useQuery } from 'react-query';
import { useAccountsStateContext } from 'context/Accounts/AccountsContext';
import { EntriesAPI } from 'services/api';
import { Account, ContentType, EntriesResponse } from 'services/api/types';

export const useFetchByContentType = (contentTypeId: ContentType, selectedAccount?: Account) => {
  return useQuery(
    [contentTypeId, `${selectedAccount ? selectedAccount.account_name : ''}`],
    () =>
      EntriesAPI.filterEntries(contentTypeId, {
        accountId: selectedAccount?.id || '',
      }),
    {
      enabled: !!selectedAccount,
      staleTime: 5 * 60 * 1000,
    },
  );
};

type FetchAllDataReturnType = {
  generalData?: EntriesResponse;
  menuData?: EntriesResponse;
  categoryData?: EntriesResponse;
  menuItemData?: EntriesResponse;
  isLoading: boolean;
};
export const useFetchAllData = (): FetchAllDataReturnType => {
  const { state: accountsState } = useAccountsStateContext();
  const { selectedAccount } = accountsState;

  const { data: generalData, isLoading: generalLoading } = useFetchByContentType(
    'general',
    selectedAccount,
  );
  const { data: menuData, isLoading: menuLoading } = useFetchByContentType('menu', selectedAccount);
  const { data: categoryData, isLoading: categoryLoading } = useFetchByContentType(
    'category',
    selectedAccount,
  );
  const { data: menuItemData, isLoading: menuItemsLoading } = useFetchByContentType(
    'menuItem',
    selectedAccount,
  );

  const isLoading = generalLoading || menuLoading || categoryLoading || menuItemsLoading;
  return {
    generalData: generalData,
    menuData: menuData,
    categoryData: categoryData,
    menuItemData: menuItemData,
    isLoading,
  };
};
