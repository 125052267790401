import React from 'react';
import { chakra, FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react';

type FormSelectProps = {
  label: string;
  name: string;
  value: string;
  items: string[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  error?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
};

export const DefaultFormSelect: React.FC<FormSelectProps> = ({
  label,
  name,
  value,
  onChange,
  items,
  placeholder,
  error,
  required = false,
  ...rest
}) => {
  return (
    <FormControl
      display="flex"
      isInvalid={!!error}
      isRequired={required}
      flexDirection="column"
      marginBottom="3"
      {...rest}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        name={name}
        value={value}
        onChange={onChange}
        backgroundColor="gray.50"
        placeholder={placeholder}
      >
        {items.map((item) => {
          return (
            <option value={item} key={item}>
              {item}
            </option>
          );
        })}
      </Select>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
export const FormSelect = chakra(DefaultFormSelect);
